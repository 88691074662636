exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accomodations-cabana-js": () => import("./../../../src/pages/accomodations/cabana.js" /* webpackChunkName: "component---src-pages-accomodations-cabana-js" */),
  "component---src-pages-accomodations-dormitory-js": () => import("./../../../src/pages/accomodations/dormitory.js" /* webpackChunkName: "component---src-pages-accomodations-dormitory-js" */),
  "component---src-pages-accomodations-enchanted-cabin-js": () => import("./../../../src/pages/accomodations/enchanted-cabin.js" /* webpackChunkName: "component---src-pages-accomodations-enchanted-cabin-js" */),
  "component---src-pages-accomodations-index-js": () => import("./../../../src/pages/accomodations/index.js" /* webpackChunkName: "component---src-pages-accomodations-index-js" */),
  "component---src-pages-accomodations-mother-house-js": () => import("./../../../src/pages/accomodations/mother-house.js" /* webpackChunkName: "component---src-pages-accomodations-mother-house-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-know-thyself-js": () => import("./../../../src/pages/know-thyself.js" /* webpackChunkName: "component---src-pages-know-thyself-js" */)
}

